const initialState = {
  resultsData: [],
  formats: [],
  isLoading: false,
  fieldList: [],
  externalFormats: [],
  externalCity: null,
  city: null,
};

export const ActionType = {
  set_results: `SET_RESULTS`,
  SET_FORMATS: `SET_FORMATS`,
  SET_CITY: `SET_CITY`,
  SET_IsLOADING: 'SET_IsLOADING',
  SET_FIELDS: 'SET_FIELDS',
  SET_EXTERNAL_FORMATS: 'SET_EXTERNAL_FORMATS',
  SET_EXTERNAL_CITY: 'SET_EXTERNAL_CITY',
};

export const ActionCreator = {
  setResults: (results) => ({
    type: ActionType.set_results,
    payload: results,
  }),
  setFields: (fields) => ({
    type: ActionType.SET_FIELDS,
    payload: fields,
  }),
  /*   setFormats: (results) => ({
      type: ActionType.SET_FORMATS,
      payload: results,
    }), */
  setExternalFormats: (results) => ({
    type: ActionType.SET_EXTERNAL_FORMATS,
    payload: results,
  }),
  /*   setCity: (results) => ({
      type: ActionType.SET_CITY,
      payload: results,
    }), */
  setExternalCity: (results) => ({
    type: ActionType.SET_EXTERNAL_CITY,
    payload: results,
  }),
  setIsLoading: (isLoading) => ({
    type: ActionType.SET_IsLOADING,
    payload: isLoading,
  }),
};

export const Operation = {
  /*   getFormats:
      ({ event_city_id }) =>
        async (dispatch, getState, api) => {
          const response = await api.get(
            `/api/event/event_format/${event_city_id}?hidden=true`
          );
  
          if (response.status === 200)
            dispatch(ActionCreator.setFormats(response.data.values));
          dispatch(ActionCreator.setCity(response.data.city));
        }, */
  getExternalFormats:
    ({ event_city_id }) =>
    async (dispatch, getState, api) => {
      try {
        const response = await api.get(
          `/api/external/formats/${event_city_id}`
        );
        if (response.status === 200) {
          dispatch(ActionCreator.setExternalFormats(response.data.values));
          dispatch(ActionCreator.setExternalCity(response.data.city));
          dispatch(ActionCreator.setIsLoading(false));
        }
      } catch {
        dispatch(ActionCreator.setIsLoading(false));
      }
    },
  /*   getResults: (formatPublicId) => async (dispatch, getState, api) => {
      dispatch(ActionCreator.setIsLoading(true));
      const response = await api.get(`/api/results/format/${formatPublicId}`);
  
      if (response.status === 200) {
  
        dispatch(ActionCreator.setResults(response.data.values));
        dispatch(ActionCreator.setIsLoading(false));
        dispatch(ActionCreator.setFields(response.data.settings.formats))
      }
    }, */
  getExternalResults:
    (external_format, event_city_public_id) =>
    async (dispatch, getState, api) => {
      dispatch(ActionCreator.setIsLoading(true));
      const response = await api.get(
        `/api/event_results/${external_format}/${event_city_public_id}`
      );

      if (response.status === 200) {
        dispatch(ActionCreator.setResults(response.data.values));
        dispatch(ActionCreator.setIsLoading(false));
        dispatch(ActionCreator.setFields(response?.data?.settings?.formats));
      }
    },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionType.set_results:
      return { ...state, resultsData: action.payload };
    case ActionType.SET_FORMATS:
      return { ...state, formats: action.payload };
    case ActionType.SET_EXTERNAL_FORMATS:
      return { ...state, externalFormats: action.payload };
    case ActionType.SET_EXTERNAL_CITY:
      return { ...state, externalCity: action.payload };
    case ActionType.SET_CITY:
      return { ...state, city: action.payload };
    case ActionType.SET_IsLOADING:
      return { ...state, isLoading: action.payload };
    case ActionType.SET_FIELDS:
      return { ...state, fieldList: action.payload };
    default:
      return state;
  }
};
